import { isMobile } from './segment/segment-utils';
import { getCookie } from './modules/util';

(function() {
  const IS_PROD = location.host === 'razorpay.com';
  // Create a queue, but don't obliterate an existing one!
  var analytics = (window.analytics = window.analytics || []);

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return;

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.');
    }
    return;
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true;

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
    'addSourceMiddleware',
    'addIntegrationMiddleware',
    'setAnonymousId',
    'addDestinationMiddleware'
  ];

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function(method) {
    return function() {
      var args = Array.prototype.slice.call(arguments);
      args.unshift(method);
      analytics.push(args);
      return analytics;
    };
  };

  // For each of our methods, generate a queueing stub.
  for (var i = 0; i < analytics.methods.length; i++) {
    var key = analytics.methods[i];
    analytics[key] = analytics.factory(key);
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function(key, options) {
    // Create an async script element based on your key.
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';

    // Insert our script next to the first script element.
    var first = document.getElementsByTagName('script')[0];
    first.parentNode.insertBefore(script, first);
    analytics._loadOptions = options;
  };

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0';

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  let segmentKey = 'rPeVVDHGV6FOeuBaTSTCDuMVytcWe54Y'; // staging key
  let xSegmentKey = 'ztpTIh49JFB68IBIXSgXpkykQ9pgT7p6'; // x website staging key
  if (location.hostname === 'razorpay.com') {
    segmentKey = 'YNK2Ge3yl0IaYj8PLndL5V6vfPZPQxZo';
    xSegmentKey = 'a3NqjBj0c1MoriJagabg4GUvVOh8zLoo'; // x website prod key
  }

  if (location.href.includes('/x/')) {
    analytics.load(xSegmentKey);
  } else {
    analytics.load(segmentKey);
  }

  // Make the first page call to load the integrations. If
  // you'd like to manually name or tag the page, edit or
  // move this call however you'd like.

  analytics.identify({
    subscriptionStatus: 'inactive',
    anonymousId: localStorage.getItem('ajs_anonymous_id'),
    device_type: isMobile() ? 'mweb' : 'dweb',
    clientId: getCookie('clientId')
  });

  analytics.page({
    anonymousId: localStorage.getItem('ajs_anonymous_id'),
    url: location.href,
    clientId: getCookie('clientId')
  });

  function onAnalyticsSdkLoad() {
    if (!window.universeAnalytics) {
      return;
    }

    window.universeAnalytics.init_EXPERIMENTAL({
      enableAutomaticEvents: true,
      defaultGlobalProperties: {
        pageType: 'Others',
        l1FunnelStage: 'Pre-signup flow',
        l2FunnelStage: 'Page View'
      },
      destinations: {
        lumberjack: {
          key: 'ezGnY9wN3IjNwgDM3MDMygzNwMTO04yy',
          appName: 'onboarding-new-events',
          url: 'https://lumberjack.razorpay.com/v1/track'
        },
        segment: {
          key: 'YNK2Ge3yl0IaYj8PLndL5V6vfPZPQxZo',
          priority: 'high'
        }
      },
      locale: 'IN', //old infra website is only for IN locale
      mode: IS_PROD ? 'live' : 'debug'
    });

    window.universeAnalytics.markPageAsLoaded_EXPERIMENTAL();
  }

  var analyticsSdkJs = document.createElement('script');
  analyticsSdkJs.defer = true;
  analyticsSdkJs.src =
    'https://razorpay.com/build/analytics-sdk/analytics-sdk-min.js';
  analyticsSdkJs.onload = onAnalyticsSdkLoad;
  document.documentElement.appendChild(analyticsSdkJs);
})();
